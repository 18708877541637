'use strict';

const isInIframeDetection = function() {
	return (window && window.parent && window !== window.parent);
}

const redirectFunction = function(merchantKey, checkoutUrl, config, newWindow) {
	var rlocation  = document.createElement('a');        // redirect location
	rlocation.href = checkoutUrl;

	if ('' === rlocation.pathname) {
		throw Error('invalid checkout url');
	}

	var redirectUrl = rlocation.protocol + '//' + rlocation.host + rlocation.pathname;
	redirectUrl += rlocation.search ? rlocation.search : '?p=0';  // indicator- no params exists
	redirectUrl += '&merchant=' + merchantKey;
	redirectUrl += '&config=' + (config ? encodeURIComponent(JSON.stringify(config)) : '');
	redirectUrl += '&preset=' + (rlocation.hash ? encodeURIComponent(decodeURIComponent(rlocation.hash.substring(1))) : '');

	if (newWindow === true || isInIframeDetection()) {
		if (isInIframeDetection()) {
			console.warn('Opened checkout page in new page cause client component was loaded in iframe. We recommend to load the client component in parent window.')
		}
		window.open(redirectUrl, '_blank'); // blank otherwise an error can occure if you try to open it twice with a reload between
	} else {
		window.location.href = redirectUrl;
	}
}

/**
 * redirect to checkout page rather loading in iframe (not suggested - use only if iframe solution is not possible)
 * @param {string} merchantKey
 * @param {string} checkoutUrl
 * @param {object|null} config
 * @param {bool} newWindow
 */
function redirect(merchantKey, checkoutUrl, config, newWindow) {
	redirectFunction(merchantKey, checkoutUrl, config, newWindow);
}

/**
 * embed cpay-checkout into page
 * @param {object} container Document parent object (ie. document.body or document.getElementById('checkoutContainer'))
 * @param {string} merchantKey Unique merchant identifier key
 * @param {string|null} checkoutUrl As provided from Communipay enrollment step
 * @param {function} onMessageReceive Function callback to be triggered after any message response
 * @param {object|null} config Optional config parameter to override layout schema
 */
function create(container, merchantKey, checkoutUrl, onMessageReceive, config) {

	if (!checkoutUrl || '' === checkoutUrl) {
		throw Error('invalid checkout url');
	}

	if (isInIframeDetection()) {
		redirectFunction(merchantKey, checkoutUrl, config, true);
		return null;
	}

	var ilocation  = document.createElement('a');        // iframe location
	ilocation.href = checkoutUrl;

	if ('' === ilocation.pathname) {
		throw Error('invalid checkout url');
	}
	var locationPieces = ilocation.pathname.replace(/\/+$/, '').split('/');  // enrollment id is last piece
	var enrollmentId   = locationPieces[2];

	var iframeId   = 'checkout_' + enrollmentId;
	var iframeData = {
		cUrl:    ilocation.protocol + '//' + ilocation.host + ilocation.pathname,
		cParams: ilocation.search,
		cConfig: config ? encodeURIComponent(JSON.stringify(config)) : '',
		cPreset: ilocation.hash ? encodeURIComponent(decodeURIComponent(ilocation.hash.substring(1))) : ''
	};

	var iframeUrl    = iframeData.cUrl;
	var iframeHeight = config && config.theme && config.theme.maxHeight ? config.theme.maxHeight : '100%';
	var iframeWidth  = config && config.theme && config.theme.maxWidth ? config.theme.maxWidth : '100%';
	if (iframeHeight === '100vh') {
		iframeHeight = '100%';  // does not support 100vh
	}

	iframeUrl += iframeData.cParams ? iframeData.cParams : '?p=0';  // indicator- no params exists
	iframeUrl += '&config=' + iframeData.cConfig;
	iframeUrl += '&preset=' + iframeData.cPreset;

	var iframe = '<iframe';
	iframe += ' id="' + iframeId + '"';
	iframe += ' name="' + iframeId + '"';
	iframe += ' src="' + iframeUrl + '"';
	iframe += ' allow="payment; microphone *; camera *"'; //https://check.communipay.net https://webavs.campoints.net https://agerator.io
	iframe += ' frameborder="0"';
	iframe += ' scrolling="no"';
	iframe += ' style="overscroll-behavior: none; position: fixed; width: ' + iframeWidth + '; height: ' + iframeHeight + ';"';
	iframe += ' />';

	var checkoutListener = function(event) {
		try {
			if (event.origin !== ilocation.protocol + '//' + ilocation.host) {
				return;
			}
			if (!event.data || !event.data.id || event.data.id !== enrollmentId) {
				throw Error('Invalid message response');
			}

			switch (event.data.action) {

				case 'message':
					// call parent handler
					if (onMessageReceive && typeof onMessageReceive === 'function') {
						onMessageReceive(event.data);
					}
					break;

				case 'redirect':
					if (event.data.redirectTarget === '_blank') {
						var win = window.open(event.data.redirectUrl, 'popup_' + event.data.id);
						if (null === win || (typeof win === 'undefined')) {
							// if popup blocker is active, we excpect the user to click the button in the redirect view
							// var link = '<a href="' + event.data.redirectUrl + '" target="_blank" />Bitte hier klicken</a>';
						} else {
							document.getElementById(iframeId).contentWindow.postMessage({  // confirm popup via postMessage
								id:          enrollmentId,
								action:      'popupCreated',
								redirectUrl: event.data.redirectUrl
							}, '*');
						}
					} else {
						window.location.href = event.data.redirectUrl;
					}
					break;

				case 'redirectHtml':
					var redirWin = window.open('about:blank', 'popup_' + event.data.id);
					if (null === redirWin || (typeof redirWin === 'undefined')) {
						// if popup blocker is active, we excpect the user to click the button in the redirect view
						// var link = '<a href="' + event.data.redirectUrl + '" target="_blank" />Bitte hier klicken</a>';
					} else {
						redirWin.document.write(window.atob(event.data.redirectUrl));
						document.getElementById(iframeId).contentWindow.postMessage({  // confirm popup via postMessage
							id:          enrollmentId,
							action:      'popupCreated',
							redirectUrl: event.data.redirectUrl
						}, '*');
					}
					break;

				case 'redirectInnerIframe':
					document.getElementById('checkout_' + event.data.id).src = decodeURIComponent(event.data.value);
					break;

				case 'history':
					if (event.data.value === 'refresh') {
						var src = document.getElementById('checkout_' + event.data.id).src;
						if (event.data.params && src.indexOf('returnParams') === -1) { //get parameter from returnurl .../return/... used by avs passed to communipay via initialfetch
							src += src.indexOf('?') !== -1 ? '&' : '?';
							src += 'returnParams=' + encodeURIComponent(JSON.stringify(JSON.parse(event.data.params)));  // remove json escaping, add url encoding
						}
						document.getElementById('checkout_' + event.data.id).src = src;
					} else {  // pass thru  //if (event.data.value === 'close')
						if (onMessageReceive && typeof onMessageReceive === 'function') {
							onMessageReceive(event.data);
						}
					}
					break;

				default:
					throw Error('Unhandled message response action');
			}
		} catch (e) {
			console.log(e);
		}
	};

	if (window.addEventListener) {
		window.addEventListener('message', checkoutListener, false);
	} else if (window.attachEvent) {
		window.attachEvent('message', checkoutListener);
	}

	// add meta tag for mobile view
	var meta     = document.createElement('meta');
	meta.name    = "viewport";
	meta.content = "width=device-width, initial-scale=1, shrink-to-fit=no";
	document.getElementsByTagName('head')[0].appendChild(meta);

	container = container ? container : document.body;
	if (container === document.body) {
		while (container.firstChild) {
			container.removeChild(container.firstChild);
		}
	}
	container.insertAdjacentHTML('beforeend', iframe);

	return {
		iframe:   document.getElementById('checkout_' + enrollmentId),
		listener: checkoutListener
	};
}

/**
 * dispose embedded checkout
 * @param checkout object
 */
function dispose(checkout) {
	//if checkout isnt properly set dont try to remove things
	if (checkout === null) {
		return null;
	}

	// detach handler
	if (window.removeEventListener) {
		window.removeEventListener('message', checkout.listener);
	} else if (window.detachEvent) {
		window.detach('message', checkout.listener);
	}

	// remove iframe
	var checkoutContainer = checkout.iframe && checkout.iframe.parentElement ? checkout.iframe.parentElement : null;
	if (checkoutContainer) {
		while (checkoutContainer.firstChild) {
			checkoutContainer.removeChild(checkoutContainer.firstChild);
		}
	}

	// remove added meta tag
	var meta = document.getElementsByName('viewport');
	if (meta.length > 0) {
		meta[meta.length - 1].remove();
	}

	return null;
}

export {dispose, create, redirect}