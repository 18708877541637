/* globals VXConfig */

import React                                         from 'react';
import {render, unmountComponentAtNode}              from 'react-dom';
import ReloadHelper                                  from "../../utils/ReloadHelper";
import withSuspense                                                        from '../HigherOrderComponents/Utility/withSuspense.js';
import {addPreventScrolling, getCookie, removePreventScrolling, setCookie} from '../../utils/CommonUtils';
import Flux                                                                from '../../flux/Flux.js';

const ModalBoxToyControl            = React.lazy(() => import('./ModalBoxToyControl'));
const ModalBoxConversionChat        = React.lazy(() => import('./ModalBoxConversionChat'));
const ModalBoxVip30                 = React.lazy(() => import("./ModalBoxVip30"));
const ModalBoxBlackFriday2020       = React.lazy(() => import("./ModalBoxBlackFriday2020"));
const ModalBoxAvs                   = React.lazy(() => import("./ModalBoxAvs"));
const ModalBoxCalendar2022          = React.lazy(() => import("./ModalBoxCalendar2022"));
const ModalBoxTagRewardBasic        = React.lazy(() => import("./ModalBoxTagRewardBasic"));
const ModalBoxTagRewardAdvanced     = React.lazy(() => import("./ModalBoxTagRewardAdvanced"));
const ModalBoxTagRewardPro          = React.lazy(() => import("./ModalBoxTagRewardPro"));
const ModalBoxActorGiftCalendar     = React.lazy(() => import("./ModalBoxActorGiftCalendar"));
const ModalBoxNotEnoughMoney        = React.lazy(() => import('./ModalBoxNotEnoughMoney'));
const ModalBoxNotLexyValentine      = React.lazy(() => import('./ModalBoxLexyValentine'));
const ModalBoxVideoWithPlayer       = React.lazy(() => import('./ModalBoxVideoWithPlayer'));
const ModalBoxLexyKalender2023      = React.lazy(() => import("./ModalBoxLexyKalender2023"));
const ModalBoxFeedSignup            = React.lazy(() => import("./ModalBoxFeedSignup"));
const ModalBoxSexoleLogin           = React.lazy(() => import('./ModalBoxSexoleLogin'));
const ModalBoxEsAVS                 = React.lazy(() => import('./ModalBoxEsAVS'));
const ModalboxOnboarding            = React.lazy(() => import('./ModalBoxOnboarding'));
const ModalboxExitIntent            = React.lazy(() => import('./ModalBoxExitIntent'));
const ModalBoxSpecialTopUp          = React.lazy(() => import("./ModalBoxSpecialTopUp.js"));
const ModalBoxModelConversion       = React.lazy(() => import('./ModalBoxModelConversion.js'));
const ModalBoxSevenDaysBonus        = React.lazy(() => import('./ModalBoxSevenDaysBonus.js'));
const ModalBoxFiveDaysBonus         = React.lazy(() => import('./ModalBoxFiveDaysBonus.js'));
const ModalBoxFifteenMinutesPreview = React.lazy(() => import('./ModalBoxFifteenMinutesPreview.js'));
const ModalBoxVXCoinInfo            = React.lazy(() => import('./ModalBoxVXCoinInfo'));
const ModalboxOnboardingChoose      = React.lazy(() => import('./ModalBoxOnboardingChoose'));
const ModalBoxWrapperExample        = React.lazy(() => import('./ModalBoxWrapper/ModalBoxWrapperExample'));
const ModalBoxRemovePhotos          = React.lazy(() => import('./ModalBoxRemovePhotos'));


const ModalBoxEnum = {
    CONVERSION_CHAT:         'conversion-chat',
    TOY_CONTROL:             'toy-control',
    VIP_30:                  'vip30',
    BLACK_FRIDAY_2020:       'black-friday-2020',
    AVS:                     'avs',
    CALENDAR_2022:           'vxcalendar2022-bonus',
    TAG_REWARD_BASIC:        'tag-reward-basic',
    TAG_REWARD_PRO:          'tag-reward-pro',
    TAG_REWARD_ADVANCED:     'tag-reward-advanced',
    ACTOR_GIFT_CALENDAR:     'actor-gift-calendar',
    NOT_ENOUGH_MONEY:        'not-enough-money',
    LEXY_VALENTINE:          'lexy-valentine',
    LEXYKALENDER2022:        'lexykalender2022',
    LEXYKALENDER2023:        'lexykalender2023',
    VIDEOS_WITH_PLAYER:      'video-with-player',
    FEED_SIGNUP:             'feed-signup',
    SEXOLE_LOGIN:            'sexole-login',
    ES_AVS:                  'es-avs',
    EXIT_INTENT:             'exitIntent',
    SPECIAL_TOP_UP:          'specialTopUp',
    VXMODELSITES_CONVERSION: 'vxmodelsites-conversion',
    SEVEN_DAYS_BONUS:        'seven-days-bonus',
    FIVE_DAYS_BONUS:         'five-days-bonus',
    ONBOARDING:              'onboarding',
    ONBOARDING_CHOOSE:       'onboarding-choose',
    FIFTEEN_MINUTES_PREVIEW: 'fifteen-minutes-preview',
    VXCOIN_INFO:             'vxcoin-info',
    MODAL_WRAPPER_EXAMPLE:   'modal-wrapper-example',
    REMOVE_PHOTOS:           'remove-photos',
};

/**
 * Get instance of the ModalBox
 * @param id
 * @param props
 * @returns {*}
 */
const getModalBoxById = (id, props = {}) => {
    switch (id) {
        case ModalBoxEnum.CONVERSION_CHAT:
            return <ModalBoxConversionChat {...props} />;
        case ModalBoxEnum.TOY_CONTROL:
            ReloadHelper.reload('/' + VXConfig.language + '/cams');
            return <ModalBoxToyControl />;
        case ModalBoxEnum.VIP_30:
            return <ModalBoxVip30 {...props} />;
        case ModalBoxEnum.BLACK_FRIDAY_2020:
            return <ModalBoxBlackFriday2020 />;
        case ModalBoxEnum.AVS:
            return <ModalBoxAvs {...props} />;
        case ModalBoxEnum.CALENDAR_2022:
            return <ModalBoxCalendar2022 {...props} />;
        case ModalBoxEnum.TAG_REWARD_BASIC:
            return <ModalBoxTagRewardBasic {...props} isMobile={VXConfig.isVXMobilePlatform} />;
        case ModalBoxEnum.TAG_REWARD_ADVANCED:
            return <ModalBoxTagRewardAdvanced {...props} isMobile={VXConfig.isVXMobilePlatform} />;
        case ModalBoxEnum.TAG_REWARD_PRO:
            return <ModalBoxTagRewardPro {...props} isMobile={VXConfig.isVXMobilePlatform} />;
        case ModalBoxEnum.ACTOR_GIFT_CALENDAR:
            return <ModalBoxActorGiftCalendar {...props} isMobile={VXConfig.isVXMobilePlatform} />;
        case ModalBoxEnum.NOT_ENOUGH_MONEY:
            return <ModalBoxNotEnoughMoney {...props} />;
        case ModalBoxEnum.LEXY_VALENTINE:
            return <ModalBoxNotLexyValentine {...props} />;
        case ModalBoxEnum.LEXYKALENDER2022:
        case ModalBoxEnum.LEXYKALENDER2023:
            return <ModalBoxLexyKalender2023 {...props} />;
        case ModalBoxEnum.VIDEOS_WITH_PLAYER:
            return <ModalBoxVideoWithPlayer {...props} />;
        case ModalBoxEnum.FEED_SIGNUP:
            return <ModalBoxFeedSignup {...props} />;
        case ModalBoxEnum.SEXOLE_LOGIN:
            return <ModalBoxSexoleLogin {...props} />;
        case ModalBoxEnum.ES_AVS:
            return <ModalBoxEsAVS {...props} />;
        case ModalBoxEnum.ONBOARDING:
            return <ModalboxOnboarding {...props} />;
        case ModalBoxEnum.ONBOARDING_CHOOSE:
            return <ModalboxOnboardingChoose {...props} />;
        case ModalBoxEnum.SPECIAL_TOP_UP:
            return <ModalBoxSpecialTopUp {...props} id={id} />;
        case ModalBoxEnum.EXIT_INTENT:
            return <ModalboxExitIntent {...props} isMobile={VXConfig.isVXMobilePlatform} id={id} />;
        case ModalBoxEnum.VXMODELSITES_CONVERSION:
            return <ModalBoxModelConversion {...props} id={id} />;
        case ModalBoxEnum.SEVEN_DAYS_BONUS:
            return <ModalBoxSevenDaysBonus {...props} id={id} />;
        case ModalBoxEnum.FIVE_DAYS_BONUS:
            return <ModalBoxFiveDaysBonus {...props} id={id} />;
        case ModalBoxEnum.FIFTEEN_MINUTES_PREVIEW:
            return <ModalBoxFifteenMinutesPreview {...props} id={id} />;
        case ModalBoxEnum.VXCOIN_INFO:
            return <ModalBoxVXCoinInfo {...props} id={id} />;
        case ModalBoxEnum.MODAL_WRAPPER_EXAMPLE:
            return <ModalBoxWrapperExample {...props} id={id} />;
        case ModalBoxEnum.REMOVE_PHOTOS:
            return <ModalBoxRemovePhotos {...props} id={id} />;
        default:
    }
};

/**
 * Render ModalBox in DOM
 * @param id
 * @param props
 * @returns {closeFn}
 */
const openModalBox = (id, props = {}, onClose) => {
    if (document.getElementById(id)) {
        return null;
    }

    const containerId = 'modalbox-factory-container';
    let container     = document.getElementById(containerId);

    if (container) {
        container.parentNode.removeChild(container);
    }

    // init DOM container
    container    = document.createElement('div');
    container.id = containerId;
    if (props.overCookie) {
        container.classList.add('over-cookie');
    }
    if (props.preventScrolling) {
        addPreventScrolling();
    }
    document.body.appendChild(container);

    // default props
    const closeFn = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
        if (document.getElementById(containerId)) {
            if (props.overCookie) {
                container.classList.remove('over-cookie');
            }
            if (props.preventScrolling) {
                removePreventScrolling();
            }
            unmountComponentAtNode(document.getElementById(containerId));
            document.getElementById(containerId)?.parentNode.removeChild(document.getElementById(containerId));
        }
    };
    props.onClose = closeFn;

    const modalBox = getModalBoxById(id, props);

    render(withSuspense(modalBox), container);

    return closeFn;
};

function openVip30Modal() {
    openModalBox(ModalBoxEnum.VIP_30);
}

function openActorGiftCalendarModal(props) {
    openModalBox(ModalBoxEnum.ACTOR_GIFT_CALENDAR, props);
}

function openNotEnoughMoneyModal(props) {
    openModalBox(ModalBoxEnum.NOT_ENOUGH_MONEY, props);
}

function openOnboardingModal(props, useCookie = false) {
    if (useCookie) {
        const hasCookie = getCookie(Flux.Constants.CookieNames.HAS_USER_SEEN_ONBOARDING, true);

        if (hasCookie) {
            return;
        } else {
            setCookie(Flux.Constants.CookieNames.HAS_USER_SEEN_ONBOARDING, 1, 1, '/', '', true, Flux.Constants.CookieSameSiteAttributes.LAX);
        }
    }
    openModalBox(ModalBoxEnum.ONBOARDING, {overCookie: true, ...props});
}

export {
    getModalBoxById,
    openModalBox,
    ModalBoxEnum,
    openVip30Modal,
    openActorGiftCalendarModal,
    openNotEnoughMoneyModal,
    openOnboardingModal,
};
