/* global VXConfig */

import Pjax                             from '../pjax/index.js';
import assign                           from 'object-assign';
import {currentYPosition, getOffsetTop} from './CommonUtils';
import Flux                             from '../flux/Flux';

if (typeof window === 'object') {
	window._pjaxUnloadFuncs = [];
}

const defaultSelectors = [
	'head > title',
];

let forceScrollToTop      = false;
let ignoreNextPjaxScroll  = false;
let onBeforeSendCallbacks = [];

export default {

	init: (options) => {

		if (!window._pjaxInstance) {
			const pjaxOptions = assign({}, options);

			pjaxOptions.selectors         = defaultSelectors.concat(options.selectors);
			pjaxOptions.onBeforePushState = (opts) => {
				if (opts.history && history && history.state) {
					const stateOptions     = opts.stateOptions || {};
					const state            = history.state;
					state.pjaxStateOptions = stateOptions;
					history.replaceState(state, '', window.location.href);
				}
			};
			pjaxOptions.onBeforeSend      = (opts) => {
				if (history) {
					history.scrollRestoration = 'manual';

					if (opts.history) {
						opts.stateOptions           = opts.stateOptions || {};
						opts.stateOptions.scrollTop = currentYPosition();
					}
				}

				// If a single callback returns false, the page load will be canceled
				return onBeforeSendCallbacks.reduce((accumulator, callback) => callback(opts) && accumulator, true);
			};

			const pjaxInstance = new Pjax(pjaxOptions);

			// listener for dynamic elements
			document.body.addEventListener('vx:reload-pjax', function(event) {
				const url = event.detail.url;
				const origEvent = event.detail.origEvent;

				const opts = {};

				if (origEvent && origEvent.target) {
					opts.el = origEvent.target;
				}
				pjaxInstance.loadUrl(url ? url : window.location.href, assign(opts, pjaxInstance.options));
			});
			document.body.addEventListener('vx:reload-current-pjax', function() {
				pjaxInstance.loadUrl(window.location.href, assign({}, pjaxInstance.options, {history: false}));
			});

			document.addEventListener('pjax:beforeUnmountElement', function() {
				// unload all
				let clb;
				while ((clb = window._pjaxUnloadFuncs.pop())) {
					clb();
				}
			});
			document.addEventListener('pjax:success', function(event) {
				// we need a short timeout for react renderer, to get the correction top positions
				window.setTimeout(() => {
					if (!ignoreNextPjaxScroll) {
						if (!event.history) {
							if (history && history.state && history.state.pjaxStateOptions && typeof history.state.pjaxStateOptions.scrollTop !== 'undefined') {
								// update incentives for this page call
								Flux.Conversion.loadPageLoadIncentives(VXConfig.routeName, VXConfig.routeArgs);

								document.documentElement.scrollTop = history.state.pjaxStateOptions.scrollTop;
							}
							return;
						}

						// scroll to defined element
						const elementId = event.el && event.el.dataset.pjaxScrollto;
						let $element    = elementId ? document.querySelector('#pjax-scrollto-' + elementId) : null;
						let offset      = 0;
						if (forceScrollToTop) {
							$element         = document.querySelector('#pjax-scrollto-top');
							forceScrollToTop = false;
						}

						if ((!$element) && (window.location.search.indexOf('page=') > -1 || window.location.search.indexOf('sort=') > -1)) {
							$element = document.querySelector('#pjax-scrollto-pagination');
						}

						if ((!$element) && window.location.search.indexOf('noscroll=') === -1) {
							$element = document.querySelector('#pjax-scrollto-view');
						}

						if ((!$element) && window.location.search.indexOf('noscroll=') === -1) {
							$element = document.querySelector('#pjax-scrollto');
							offset   = 35;
						}
						if ($element) {
							document.documentElement.scrollTop = getOffsetTop($element) - offset;
						}
					} else {
						ignoreNextPjaxScroll = false;
					}

					// update incentives for this page call
					Flux.Conversion.loadPageLoadIncentives(VXConfig.routeName, VXConfig.routeArgs);
				}, 200);
			});
			document.addEventListener('pjax:error', (e) => {
				if (typeof options.onError === 'function') {
					options.onError(e);
				}
			});

			window._pjaxInstance = pjaxInstance;
		}
	},

	resetFirstRun: () => {
		if (window._pjaxInstance) {
			window._pjaxInstance.firstrun = true;
		}
	},

	addUnloadFunc: (clb) => {
		if (clb) {
			window._pjaxUnloadFuncs.push(clb);
		}
	},

	reload: (url, e) => {
		if (window._pjaxInstance && document.body) {
			document.body.dispatchEvent(new CustomEvent("vx:reload-pjax", {'detail': {url: url, origEvent: e}}));
		} else {
			if (url) {
				window.location.href = url;
			} else {
				window.location.reload();
			}
		}
	},

	reloadCurrentPage: () => {
		if (window._pjaxInstance && document.body) {
			document.body.dispatchEvent(new CustomEvent("vx:reload-current-pjax"));
		} else {
			window.location.reload();
		}
	},

	setTemporaryScrollToTop() {
		forceScrollToTop = true;
	},

	setIgnoreNextPjaxScroll() {
		ignoreNextPjaxScroll = true;
	},

	addOnBeforeSendCallback(callback) {
		onBeforeSendCallbacks.push(callback);
	},

	removeOnBeforeSendCallback(callback) {
		onBeforeSendCallbacks = onBeforeSendCallbacks.filter(cb => cb !== callback);
	},

};
