import {create, dispose} from '../vendor/cpay-checkout-client/cpay-checkout-client@1.3.2.js';

import Flux  from "../flux/Flux";

let onFlowStart = () => {};
let onFlowEnd = () => {};

export function setOnFlowStart(callback) {
	onFlowStart = callback;
}

export function setOnFlowEnd(callback) {
	onFlowEnd = () => {
		callback();

		// always reset callbacks on flow end
		onFlowStart = () => {};
		onFlowEnd = () => {};
	};
}

export function openAvs() {
	function handleAvsPayRequest(avsPayRequestResult) {
		const checkoutLink = avsPayRequestResult.checkoutLink;
		Flux.Guest.removeAvsPayRequestListener(handleAvsPayRequest);

		const iFrameContainer = document.createElement('div');
		iFrameContainer.classList.add('cpay-iframe-container');
		document.body.appendChild(iFrameContainer);

		const checkout = create(
			iFrameContainer,
			'abcdef-12345-ghijk-67890',
			checkoutLink,
			function(message) {
//				console.log(message);

				if (message && message.action === 'history' && (message.value === 'close' || message.value === 'finish')) {
					dispose(checkout);
					iFrameContainer.remove();

					onFlowEnd();
				}
			},
			{
				theme:                  {
					formFieldVariant: 'filled',
					showHeader:       false,
					showClose:        true,
				},
				selectPaymentTypeFirst: false,
			}
		);
	}

	Flux.Guest.addAvsPayRequestListener(handleAvsPayRequest);
	Flux.Guest.perpareAvsPayRequest();

	onFlowStart();
}
