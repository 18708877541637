'use strict';
import {EventEmitter} from 'events';

function GlobalEventHandler() {
	this.PROSEARCH_SHOW_MENU                            = 'prosearch_show_menu';
	this.MESSENGER_SEARCH_TOGGLE                        = 'messengerSearchToggle';
	this.MESSENGER_SEARCH_VISIBILITY_CHANGE             = 'messengerSearchVisibilityChange';
	this.MESSENGER_SEARCH_STARTED                       = 'messengerSearchStarted';
	this.MESSENGER_SIDEBAR_TOGGLE                       = 'messengerSidebarToggle';
	this.GALLERY_SHOW_MODAL                             = 'galleryShowModal';
	this.GALLERY_SELECT                                 = 'gallerySelect';
	this.GALLERY_MOVE                                   = 'galleryMove';
	this.FREE_SHOW_STOP                                 = 'freeShowStop';
	this.LOADER_TOGGLE                                  = 'loaderToggle';
	this.GALLERY_VIDEO_STARTED                          = 'galleryVideoStarted';
	this.CONVERSION_CHAT_TIMER_ENDED                    = 'conversionChatTimerEnded';
}

GlobalEventHandler.prototype = Object.create(EventEmitter.prototype);

export default new GlobalEventHandler();