import forEachEls from "./foreach-els";
import evalScript from "./eval-script";
// Finds and executes scripts (used for newly added elements)
// Needed since innerHTML does not run scripts
export default function(el) {
  // console.log("going to execute scripts for ", el)
  forEachEls(el.querySelectorAll("script"), function(script) {
    if (!script.type || script.type.toLowerCase() === "text/javascript") {
      if (script.parentNode) {
        script.parentNode.removeChild(script)
      }
      evalScript(script)
    }
  })
}
